<template>
    <tr>
        <td class="okrFirstColumn w-[3%]"></td>
        <td class="okrSecondColumn w-[3%]"></td>
        <td class="font-bold w-[20%]"><span>Name</span></td>              
        <td class="font-bold w-[20%] text-center"><span>Team</span></td>
        <td class="font-bold w-[10%] text-center"><span>Owner</span></td>
        <td class="font-bold w-[15%] text-center"><span >Progress</span></td>
        <!-- <td class="font-bold text-black w-[90%] text-center">Status</td> -->
        <td class="font-bold w-[10%] text-center"><span >Zeitraum</span></td>
        <td class="font-bold w-[15%] text-center"><span>Aktionen</span></td>
    </tr>
</template>

<script>

export default {
    name: 'Component_OKR_Header',
}
</script>
