<template>
  <!-- <nav class="fixed top-0 left-0 h-screen w-55 flex flex-col bg-gray-100 shadow"> -->
  <nav class="fixed pt-5 pb-5 pl-1 pr-1 top-0 left-0 h-screen w-44 flex flex-col bg-gray-100 dark:bg-gray-800 shadow">
    <!-- <div class="navbarItem">
      &nbsp;
    </div> -->

    <!-- <div>
      <Component_Sidebar_Icon :icon="HomeIcon" />
    </div> -->

    <!-- <div class="navbarItem">
      <a class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbar-menu" @click="showMobileMenu = !showMobileMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div> -->

    <!-- <Component_Sidebar_Button v-bind:text="'vanta OKR'" v-bind:link="'/overview'" /> -->
    <Component_Sidebar_Button :text="'VANTA OKR'" :link="'/okrs'" :additionalClasses="'font-bold'"/>

    <!-- <div class="pl-2">
      <form method="get" action="/search">
        <div class="flex center items-center mr-1">
            <input type="text" class="input-field-medium" placeholder="    Suche" name="query">
            <button class="button is-dark">
              <span class="icon">
                <MagnifyingGlassIcon class="h-6 w-6 text-white" />
              </span>
            </button>
        </div>
      </form>
    </div> -->

    <!-- <Component_Sidebar_Button :text="'Übersicht'" :link="'/overview'" /> -->
    <Component_Sidebar_Button :text="'OKR'" :link="'/okrs'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Teams'" :link="'/teams'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Firmenübersicht'" :link="'/company'" :isBold="false"/>

    <Component_Sidebar_Button :text="'OKR Archiv'" :link="'/okrs-archive'" :isBold="false"/>
    <Component_Sidebar_Button :text="'Changelog'" :link="'/changelog'" :isBold="false" v-if="permissions.includes('product.view_changelog')" />
    <Component_Sidebar_Button :text="'Lizenzverwaltung'" :link="'/licenses'" :isBold="false" v-if="permissions.includes('product.view_license')"/>

    <Component_Sidebar_Button :text="'Profil'" :link="'/my-account'" :isBold="false"/>
    
    <div class="navbarItem">
      <button @click="logout()" class="navbarButton">Log out</button>
    </div>
    <!-- TODO make these seperated and not with nbsp -->
    <div class="navbarItem"> &nbsp;</div> 
    <!-- <div class="navbarItem"> &nbsp;</div> -->

    <Component_Sidebar_Button :text="'Datenschutzerklärung'" :link="'/privacy-statement'" :isBold="false" :additionalClasses="'italic'"/>
    <!-- <Component_Sidebar_Button :text="'AGB'" :link="'/terms-and-conditions'" :isBold="false" :additionalClasses="'italic'"/> -->
    <Component_Sidebar_Button :text="'Impressum'" :link="'/imprint'" :isBold="false" :additionalClasses="'italic'"/>


    <!-- <div class="navbarItem">
      <router-link to="/impressum" class="button navbarButton is-light is-fullwidth">Impressum</router-link>
    </div> -->
    
    <!-- <div v-if="this.theme == 'light'" class="navbarItem">
      <button @click="toggletheme()" class="button navbarButton is-light">Dark theme</button>
    </div>
    <div v-else class="navbarItem">
      <button @click="toggletheme()" class="button navbarButton is-light">Light theme</button>
    </div> -->
  </nav>
</template>

<script>
import axios from 'axios'
import { HomeIcon } from '@heroicons/vue/24/outline';
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

import Component_Sidebar_Icon from '@/components/Component_Sidebar_Icon.vue';
import Component_Sidebar_Button from './Component_Sidebar_Button.vue';
import Search from '@/views/Search.vue';


export default {
  name: 'Component_Sidebar',
  components: {
    Component_Sidebar_Icon,
    Component_Sidebar_Button,
    MagnifyingGlassIcon,
  },
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      HomeIcon,
      // MagnifyingGlassIcon,
    }
  },
  mounted() {
    this.cart = this.$store.state.cart
    this.theme = this.$store.state.theme
    // console.log("Permissions:", this.permissions)
  },
  methods: {
    logout() {
      axios.defaults.headers.common["Authorization"] = ""

      localStorage.removeItem("token")
      localStorage.removeItem("username")
      localStorage.removeItem("userid")

      this.$store.commit('removeToken')

      this.$router.push('/log-in-to-okr')
    },
  },
}
</script>