<template>
  <div class="infopage">
    <h1>Impressum</h1>

      <p>
          Moritz Reiss<br>
          Hildastraße 9D<br>
          76534 Baden-Baden<br>
          Deutschland
      </p>

      <p>
          Sven Willems<br>
          Danzigerstraße 4<br>
          76434 Rastatt<br>
          Deutschland
      </p>

      <p>
          Luka Johnsen<br>
          Davenstedter Str. 269<br>
          30455 Hannover<br>
          Deutschland
      </p>

      <p>
          E-Mail-Adresse: <a class="link-infotext" href="mailto:post@sustevia.de">post@sustevia.de</a><br>
          Tel: 0155-61337237
      </p>

      <h2>1. Haftung für Inhalte</h2>
        <p>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
        </p>

      <h2>2. Haftung für Links</h2>
        <p>
            Trotz sorgfältiger inhaltlicher Kontrolle zum Zeitpunkt der Linksetzung, die keinen Hinweis auf rechtswidrigen oder -verletzenden Inhalt ergab, übernehmen wir keine Haftung für den Inhalt externer Seiten, auf die mittels Link verwiesen wird. Für den Inhalt der verlinkten Seiten sind und bleiben ausschließlich deren Betreiber verantwortlich. Auf Hinweis werden Links auf unzulässigen Inhalt umgehend entfernt.
        </p>

      <h2>3. Urheberrecht</h2>
        <p>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        </p>
  </div>

</template>