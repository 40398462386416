<template>
<div class="flex flex-wrap" v-if="!isLoading">
    <div header class="w-full">
        <h2 class="is-size-3 has-text-centered pb-3">
            Changelog
        </h2>
    </div>
    <table class="table is-fullwidth no-header-border table-fixed bg-transparent">
        <thead>
            <Component_Changelog_Header/>
        </thead>
        <tbody>
            <Component_Changelog_Entry
                v-for="entry in changelog_entries"
                :changelog_entry="entry"
            />
        </tbody>
    </table>
</div>
</template>

<script>
import Component_Changelog_Entry from '@/components/Component_Changelog_Entry.vue'
import Component_Changelog_Header from '@/components/Component_Changelog_Header.vue';
import axios from 'axios'

export default {
    name: 'Changelog',
    components: {
        Component_Changelog_Header,
        Component_Changelog_Entry,
    },
    data() {
        return {
            changelog_entries: [],
            isLoading: true
        }
    },
    mounted() {
        this.fetchChangelog()
    },
    methods: {
        fetchChangelog() {
            axios.get('/api/v1/changelog/')
                .then(response => {
                    this.changelog_entries = response.data
                    console.log("Changelog entries:", this.changelog_entries)
                    this.isLoading = false
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>