<template>
    <tr class="tableRow">
        <td class="verticalCenterTD text-left">
            {{ changelog_entry.user_full_name }} (ID:{{ changelog_entry.user }})
        </td>
        <td class="verticalCenterTD text-left">
            {{ changelog_entry.object_name }} (ID:{{ changelog_entry.object_id }})
        </td>
        <td class="verticalCenterTD text-center">
            {{ changelog_entry.action }} 
        </td>
        <td class="verticalCenterTD text-center">
            {{ changelog_entry.model_name }} 
        </td>
        <td class="verticalCenterTD text-center">
            {{ formatTimestamp(changelog_entry.timestamp) }} 
        </td>
    </tr>
</template>

<script>
export default {
    name: 'Component_Changelog_Entry',
    props: {
        changelog_entry: {
            type: Object,
            Required: true
        }
    },
    mounted() {
        // console.log("Changelog entry:", this.changelog_entry)
    },
    methods: {
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit',
      }).format(date);
    }
    }
}
</script>