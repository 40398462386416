<template>
  <div>
    <!-- Your template code here -->
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
      Name ändern
    </strong>
    <table>
      <tbody>
        <tr>
          <td class="vertical-center">Vorname:</td>
          <td class="vertical-center">
            <div>
              <input type="text" class="input-field-big" v-model="newFirstName" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="vertical-center">Nachname:</td>
          <td class="vertical-center">
            <div>
              <input type="text" class="input-field-big" v-model="newLastName" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
      <button type="submit" class="button is-dark" @click="updateUser">Speichern</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Modal_MyAccount_ChangeUserEmail',
  emits: ['FullNameChanged'],
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newEmail: '',
      currentPassword: '',
      newFirstName: '',
      newLastName: '',
    };
  },
  methods: {
    async updateUser() {
      try {
        await axios.patch('/api/v1/users/me/', {
          first_name: this.newFirstName,
          last_name: this.newLastName,
        });
        this.$emit('FullNameChanged');

      } catch (error) {
        if (error.response) {
          console.error('Error changing name:', error.response.data);
        } else {
          console.error('Error changing name:', error.message);
        }
      }
    },
  },
};

</script>