<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Team bearbeiten
    </strong>
    <table>
        <tbody>
            <tr>
                <!-- ################################ Name ################################ -->
                <!-- TODO give this some space -->
                <td class="vertical-center">Name:</td>
                <td>
                    <div>
                        <input type="text" class="input-field-big" placeholder="New Team" v-model="editedTeam.name">
                    </div>
                </td>
            </tr>

                <!-- ################################ Team lead ############################ -->
            <tr>

                <td class="vertical-center">Teamlead:</td>
                <td>
                    <!-- <div class="flex-center"> -->
                    <select class="input-field-big" v-model="editedTeam.teamlead">
                        <option v-for="user in users_teamlead" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </select>
                    <!-- </div> -->
                </td>
            </tr>

                <!-- ################################ Members ################################ -->
            <tr>
                <td class="vertical-center">Teammitglieder:</td>
                <td>
                    <!-- <select multiple class="input-field-big" v-model="editedTeam.members"> 
                        <option v-for="user in users" :value="user.id">{{ user.first_name }} {{ user.last_name }}</option>
                    </select> -->
                    <multiselect
                        class="max-w-[200px] mt-1 mb-1"
                        :options="users"
                        v-model="selectedUsers"
                        :multiple="true"
                        :searchable="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        :max-height="120"
                        :selectLabel="''"
                        :deselectLabel="''"
                        placeholder="Mitglieder auswählen"
                        label="full_name"
                        track-by="id"
                        @input="updateMembers"
                    />
                </td>
            </tr>

            <!-- ################################ Checkin day ################################ -->
            <tr>
                <td class="vertical-center">Wochentag Check-in:</td>
                <td>
                    <select class="input-field-big" v-model="editedTeam.checkin_day">
                        <option v-for="(label, value) in this.dayOfWeekChoices" :key="value" :value="value">
                            {{ label }}
                        </option>
                    </select>
                    <!-- <select class="input-field-big" v-model="editedTeam.checkin_day">
                        <option value="0">Undefiniert</option>
                        <option value="1">Montag</option>
                        <option value="2">Dienstag</option>
                        <option value="3">Mittwoch</option>
                        <option value="4">Donnerstag</option>
                        <option value="5">Freitag</option>
                        <option value="6">Samstag</option>
                        <option value="7">Sonntag</option>
                    </select> -->
                </td>
            </tr>

        </tbody>
    </table>
    <!-- ####################### submit form ###################### -->
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-danger" @click="deleteTeam">Löschen</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>

</template>


<script>

import axios from 'axios'
import Multiselect from 'vue-multiselect';



export default {
    name: 'Modal_ChangeTeam',
    emits: ['teamChanged', 'teamDeleted'],
    components: { Multiselect },

    props: {
        team: {type: Object, required: true},
        permissions: {type: Array, required: true},
        users: {type: Array, required: true},
        users_teamlead: {type: Array, required: true},
    },
    data() {
        return {
            editedTeam: { ...this.team },
            dayOfWeekChoices: {
                0: 'Undefiniert',
                1: 'Montag',
                2: 'Dienstag',
                3: 'Mittwoch',
                4: 'Donnerstag',
                5: 'Freitag',
                6: 'Samstag',
                7: 'Sonntag'
            },
            name: 'Default Team',
            errors: [],
            members: [],
            teamlead: null,
            showHelpText: false,
        }
    },
    mounted() {
        console.log('editedTeam:', this.editedTeam);
        if (!this.editedTeam.checkin_day) {
            this.editedTeam.checkin_day = 0; // Default to 'Undefiniert' if not set
        }
    },
    computed: {
        selectedUsers: {
            get() {
                return this.editedTeam.members.map(memberId => this.users.find(user => user.id === memberId));
            },
            set(value) {
                this.editedTeam.members = value.map(user => user.id);
            }
        }
    },
    methods: {
        submitForm() {
            console.log('Submitting form second:', this.name, this.members, this.teamlead);
            this.errors = []

            if (this.editedTeam.name === '') {
                this.errors.push('Der Teamname fehlt')
            }
            if (this.editedTeam.members === '') {
                this.errors.push('Die Teammitglieder fehlen')
            }
            if (this.editedTeam.teamlead === '') {
                this.errors.push('Das Teamlead fehlt')
            }
            if (this.editedTeam.checkin_day === '') {
                this.errors.push('Der Wochentag für den Check-in fehlt')
            }

            if (!this.errors.length) {
                this.saveChanges();
            }
        },
        async saveChanges() {
            try {
                const response = await axios.put(`/api/v1/teams/${this.editedTeam.id}/`, this.editedTeam);
                // console.log('Team updated:', response.data);
                this.$emit('teamChanged', response.data);
            } catch (error) {
                console.error('Error updating team:', error);
            }
        },
        async deleteTeam() {
            try {
                this.$store.commit('setIsLoading', true);
                const response = await axios.delete(`/api/v1/teams/${this.team.id}`);
                // console.log('Response:', response.data);
                // Emit an event to inform the parent component
                this.$emit('teamDeleted');  // Corrected the typo here
            } catch (error) {
                console.error('Error:', error);
            } finally {
                this.$store.commit('setIsLoading', false);
            }
        },
 
    },
}
</script>