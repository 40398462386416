import { createStore } from 'vuex'
import axios from 'axios'
// import { set } from 'core-js/core/dict'
// import { is } from 'core-js/core/object'

export default createStore({
  state: {
    cart: {
      items: [],
    },
    teamsAll: [],
    teamsTeamLead: [],
    isAuthenticated: false,
    token: '',
    isLoading: false,
    theme: 'light',
    // currentUser: null,
  },
  getters: {
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      }
    },
    setTeamsTeamLead(state, teams) {
      state.teamsTeamLead = teams
    },
    setTeamsAll(state, teams) {
      state.teamsAll = teams
    },
    setPermissions(state, permissions) {
      state.permissions = permissions
    },
    // SET_CURRENT_USER(state, user) {
    //   state.currentUser = user;
    // },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)

      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setTheme(state, theme) {
      state.theme = theme
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    removeToken(state) {
      state.token = ''
      state.isAuthenticated = false
    },
  },
  actions: {
    async fetchPermissions() {
      this.commit('setIsLoading', true)
      try {
        const response = await axios.get('/api/v1/users/me/permissions'); 
        this.permissions = response.data.permissions;
        commit('setPermissions', response.data);

        console.log('permissions in store:', this.permissions);
      } catch (error) {
      } finally {
        commit('setIsLoading', false);
      }
    },
    async fetchTeamsTeamlead({ commit }) {
      // console.log('isTeamLead:', isTeamLead);
      commit('setIsLoading', true);
      // ItTeamLead = isTeamLead;
      try {
        const response = await axios.get('/api/v1/teams', {
          params: {
            teamlead_check: true,
          },
        })
        // console.log('store response:', response);
        commit('setTeamsTeamLead', response.data);
        // console.log('setTeams done. response.data:', response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        commit('setIsLoading', false);
      }
    },
    async fetchTeamsAll({ commit }) {

      commit('setIsLoading', true);
      // ItTeamLead = isTeamLead;
      try {
        const response = await axios.get('/api/v1/teams', {
          params: {
            teamlead_check: false,
          },
        })
        commit('setTeamsAll', response.data);
      } catch (error) {
        console.error('Error fetching teams:', error);
      } finally {
        commit('setIsLoading', false);
      }
    },
  },
  modules: {
  }
})
