<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Check-in für {{ this.keyresult.name }} 
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Wert:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="number" class="input-field-medium" v-model.number=editedKeyresult.value_current>
                        {{ editedKeyresult.unit }}
                    </div>
                </td>
            </tr>
            <tr>
                <td class="vertical-center">Datum:</td>
                <td class="vertical-center">
                    <div class="flex-center">
                        <input type="datetime-local" class="input-field-big-2" v-model="date">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>

    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
</template>

<script>
import axios from 'axios'
import { getToday } from '../utils/utils.js';


export default {
    name: 'Modal_ChangeKeyresult',
    emits: ['keyresultChanged', 'checkinAdded'],
    props: {
        keyresult: Object,
        team_users: Array,
    },
    data() {
        return {
            editedKeyresult: { ...this.keyresult },
            errors: [],
            date: getToday(), // Set default to current date and time
        }
    },
    mounted() {
        // console.log("editedKeyresult:", this.editedKeyresult)
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.editedKeyresult.value_current === '' || isNaN(+this.editedKeyresult.value_current)) {
                this.editedKeyresult.value_current = this.keyresult.value_current
            }
            // this.changeKeyResult()
            this.doCheckin()
        },
        async doCheckin() {
            this.$store.commit('setIsLoading', true)
            await axios
                .post(`/api/v1/checkins/`, 
                { 
                    keyresult: this.keyresult.id,
                    value: this.editedKeyresult.value_current,
                    date: this.date,
                }
                )
                .then(response => {
                this.$emit('keyresultChanged');
                this.$emit('checkinAdded')
                })
                .catch(error => {
                console.error('Error:', error)
                })
            this.$store.commit('setIsLoading', false)
        },
    }
}
</script>

<style scoped>


</style>