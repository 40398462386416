<template>
    <td class="okrFirstColumn"></td>
    <td class="okrSecondColumn"></td>

    <!-- ##################################### Name ################################## -->
    <td class="vertical-center-table table-cell">
        <button @click="isDetailModalOpen = true" class="indent text-gray-900 dark:text-gray-100">{{ keyResult.name }}</button>
        <Teleport to="#modal">
            <Transition name="modal">
                <div v-if="isDetailModalOpen" class="modal-bg" @click.self="isDetailModalOpen = false">
                    <div class="modal">
                        <button @click="isDetailModalOpen = false" class="close-btn">x</button>
                        <Modal_OKR_Keyresult_Detail
                        v-if="permissions.includes(`olp_view_keyresult_${this.keyResult.id}`)"
                        v-bind:keyresult="keyResult"
                        />
                    </div>
                </div>
            </Transition>
        </Teleport>
    </td>
    
    <!-- ##################################### Team ################################## -->
    <td></td>

    <!-- ##################################### Owner ############################ -->
    <td class="vertical-center-table table-cell text-center text-gray-900 dark:text-gray-100">
        {{ keyResult.owner_name }}
    </td>

    <!-- #################################  Progress  ##################################### -->
    <td class="vertical-center-table table-cell text-center relative">
        <div class="flex-row-wrapper">
            <span class="inline-element small-letters absolute left-1/4 text-gray-900 dark:text-gray-100">{{ keyResult.start }}</span> <!-- Example label -->
            <!-- <div class="progress-bar-container" :style="{backgroundColor: progressBarBackgroundColor, position: 'absolute', left: '50%', transform: 'translateX(-50%)'}"> -->

            <div class="progress-bar-container bg-gray-200 absolute left-[50%] translate-x-[-50%]">
                <!-- <div class="progress-bar-filled" :style="{ width: keyResult.progress_percentage + '%', backgroundColor: progressBarFrontColor }"></div> -->
                <!-- <div class="progress-bar-filled" :class="[progressBarWidthClass, progressBarFrontColorClass]"></div> -->
                <div class="progress-bar-filled" :class="[progressBarWidthClass, progressBarFrontColorClass]"></div>

                <span class="progress-value">{{ keyResult.value_current }}</span> 
            </div>
            <span class="inline-element small-letters absolute left-[70%] text-gray-900 dark:text-gray-100">{{ keyResult.goal }}</span>            
            <span class="inline-element absolute left-[80%] p-[5px] text-gray-900 dark:text-gray-100"> {{ keyResult.unit }}</span>
        </div>
    </td>

    <!--############################# status ##########################################-->
    <!-- <td class="vertical-center-table table-cell text-center">{{ keyResult.status }}</td> -->


    <!-- ########################### Zeitraum ####################### -->
    <td class="table-cell"></td>

    <!-- ########################### Actions ########################### -->
    <!-- <td v-if="permissions.includes(`olp_change_objective_${keyResult.id}`)"> -->
    <!-- <td class="vertical-center-table table-cell flex text-center"> -->
        <!-- TODO change so that you can also add Keyresult from Archive to specific Objectives -->
    <td class="verticalCenterScoped">
        <table v-if="!isArchive" class="nested-table">
            <tr>
                <td class="text-center" v-if="permissions.includes(`olp_change_keyresult_all_${this.keyResult.id}`)">
                    <!-- <div v-if="permission.includes"(`olp_`)> -->
                    <button class="actionButton"  @click="isModalFullEditOpen = true" >Ändern</button>
                    <Teleport to="#modal">
                        <Transition name="modal">
                            <div v-if="isModalFullEditOpen" class="modal-bg" @click.self="isModalFullEditOpen = false">
                                <div class="modal">
                                    <button @click="isModalFullEditOpen = false" class="close-btn">x</button>
                                    <Modal_ChangeKeyresult
                                    v-if="permissions.includes(`olp_change_keyresult_all_${this.keyResult.id}`)"
                                    v-bind:keyresult="keyResult"
                                    v-bind:team_users="team_users"
                                    v-bind:isPlanningActive="isPlanningActive"
                                    v-on:keyresultChanged="handleKeyresultChanged"
                                    />
                                </div>
                            </div>
                        </Transition>
                    </Teleport>
                    <!-- <button v-show="permissions.includes(`olp_change_keyresult_partly_${this.keyResult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyResult.id}`)" v-else @click="saveChanges()" class="button is-success">Speichern</button> -->
                </td>
                <td class="text-center" v-if="permissions.includes(`olp_change_keyresult_partly_${this.keyResult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyResult.id}`)">
                    <button class="actionButton" @click="isModalCheckinOpen = true">Check-in</button>
                    <Teleport to="#modal">
                        <Transition name="modal">
                            <div v-if="isModalCheckinOpen" class="modal-bg" @click.self="isModalCheckinOpen = false">
                                <div class="modal">
                                    <button @click="isModalCheckinOpen = false" class="close-btn">x</button>
                                    <Modal_ChangeKeyresultCheckin
                                    v-if="permissions.includes(`olp_change_keyresult_partly_${this.keyResult.id}`) || permissions.includes(`olp_change_keyresult_all_${this.keyResult.id}`)"
                                    v-bind:keyresult="keyResult"
                                    v-on:keyresultChanged="handleKeyresultChanged"
                                    />
                                </div>
                            </div>
                        </Transition>
                    </Teleport>
                    <!-- <div v-else>
                        <div>
                            &nbsp;
                        </div>
                        <button  class="button is-ghost"></button> 
                    </div> -->

                </td>
            </tr>
        </table>
    </td>

</template>

<script>
import axios from 'axios'
import Modal_ChangeKeyresult from '@/components/Modal_ChangeKeyresult.vue'
import Modal_ChangeKeyresultCheckin from '@/components/Modal_ChangeKeyresultCheckin.vue'
import Modal_OKR_Keyresult_Detail from '@/components/Modal_OKR_Keyresult_Detail.vue'

import { progressBarBackgroundColor } from '@/utils/utils.js';
import { progressBarColor } from '@/utils/utils.js';

export default {
    name: 'Component_OKR_Keyresult',
    emits: ['keyresultDeleted', 'keyresultUpdated'],
    components: {
        Modal_ChangeKeyresult,
        Modal_ChangeKeyresultCheckin,
        Modal_OKR_Keyresult_Detail,
    },
    props: {
        keyResult: Object,
        teamName: String,
        team_users: Array,
        isArchive: Boolean,
        threshold_green: Number,
        threshold_red: Number,
        isPlanningActive: Boolean,
        permissions: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            added_team_member: null,
            users: [],
            editedKeyresult: { ...this.keyresult },
            // username: null,
            customInput: '',
            customSelected: false,
            isModalFullEditOpen: false,
            isModalCheckinOpen: false,
            isDetailModalOpen: false,
        }
    },
    mounted() {
        // console.log('Keyresultlll:', this.keyResult);
    //   this.$store.dispatch('fetchTeams', false)
    },
    methods: {
        // async getUsername(userId) {
        //     try {
        //         const response = await axios.get(`/api/v1/users/${userId}/`);
        //         // this.username = response.data.username;
        //         return this.username;
        //     } catch (error) {
        //         console.error('Error getting username:', error);
        //     }
        // },
        handleKeyresultChanged() {
            this.isModalFullEditOpen = false;
            this.isModalCheckinOpen = false;
            this.$emit('keyresultUpdated');
        }
    },
    computed: {
        progressBarFrontColorClass() {
            // console.log('this.editedKeyresult.progress:', this.editedKeyresult.progress);
            // console.log('this.keyresultprogress:', this.keyResult.progress);
            // return 'bg-green-500';
            return progressBarColor(this.keyResult.progress, this.threshold_green, this.threshold_red);
        },
        progressBarBackgroundColor() {
            return progressBarBackgroundColor(this.keyResult.progress, this.threshold_green, this.threshold_red);
        },
        progressBarWidthClass() {
            const percentage = Math.round(this.keyResult.progress_percentage);
            // console.log('percentage:', this.percentage);
            // console.log('percentage:', `w-[${percentage}%]`);
            // return `w-[${percentage}%]`;
            return `w-${Math.min(Math.max(Math.round(percentage / 5) * 5, 0), 100)}pc`;

            // return 'w-[10%]';
        },
        // width: keyResult.progress_percentage + '%',
    }
}
</script>


<style scoped>


.objective-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.info-column {
  flex: 1;
  margin-right: 10px;
}

.input-field {
  width: 100%;
  height: 30px;
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editable-field {
  display: inline-block;
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 4px;
}


.key-result-row {
  display: flex; /* Enables flexbox layout */
  align-items: center; /* Correctly vertically centers the content */
}


.verticalCenterScoped {
  vertical-align: middle;
  /* text-align: center; */
} 

.nested-table {
  width: 100%;
  border-collapse: collapse; /* Optional: to remove gaps between cells */
}


.nested-table td {
  padding: 8px; /* Optional: to add padding inside cells */
  text-align: right; /* Align content to the right */
}

/* .w-0pc { width: 0%; }
.w-5pc { width: 5%; }
.w-10pc { width: 10%; }
.w-15pc { width: 15%; }
.w-20pc { width: 20%; }
.w-25pc { width: 25%; }
.w-30pc { width: 30%; }
.w-35pc { width: 35%; }
.w-40pc { width: 40%; }
.w-45pc { width: 45%; }
.w-50pc { width: 50%; }
.w-55pc { width: 55%; }
.w-60pc { width: 60%; }
.w-65pc { width: 65%; }
.w-70pc { width: 70%; }
.w-75pc { width: 75%; }
.w-80pc { width: 80%; }
.w-85pc { width: 85%; }
.w-90pc { width: 90%; }
.w-95pc { width: 95%; }
.w-100pc { width: 100%; } */

</style>
