<template>
    <div>
      <!-- <line-chart :chart-data="chartData" :options="chartOptions"></line-chart> -->
      <canvas ref="myChart" class="w-[600px] h-[400px]"></canvas>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  import { defineComponent, ref, onMounted } from 'vue';
  import { Chart, registerables } from 'chart.js';
  import { nextTick } from 'vue';
  import 'chartjs-adapter-date-fns'; // Import the date adapter


  
  Chart.register(...registerables);
  
  export default {
    name: 'Modal_OKR_Keyresult_Detail',
    props: {
      keyresult: Object,
    },
    data() {
      return {
        checkins: [],        
        myChart: null,
        checkinData: [],
        goalLine: [],
        dangerLine: [],
      };
    },
    mounted() {
      console.log("Detail Keyresult", this.keyresult);
      this.getCheckins();
      // nextTick(() => {
      //   this.renderChart();
      // });
      // this.renderChart(this.chartData, this.options);

    },
    methods: {
      async getCheckins() {
        this.$store.commit('setIsLoading', true);
        try {
          const response = await axios.get(`/api/v1/checkins/?keyresult_id=${this.keyresult.id}`);
          this.checkins = response.data;
          // console.log('Checkins:', this.checkins);
          this.checkinData = this.generateCheckinData(); // Populate chartData after checkins is fetched
          this.goalLine = this.generateGoalData();
          this.dangerLine = this.generateDangerData();
          nextTick(() => {
            this.renderChart();
          });
        } catch (error) {
          console.error('Error fetching checkins:', error);
        } finally {
          this.$store.commit('setIsLoading', false);
        }
      },
      generateCheckinData() {
        const sortedCheckins = this.checkins.sort((a, b) => new Date(a.date) - new Date(b.date));

        return this.checkins.map(checkin => ({
          x: new Date(checkin.date),
          y: checkin.value
        }));
      },
      generateGoalData() {
        return [
          { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
          { x: new Date(this.keyresult.working_period_end), y: this.keyresult.goal }
        ];
      },
      generateDangerData() {
        return [
          { x: new Date(this.keyresult.working_period_start), y: this.keyresult.start },
          { x: new Date(this.keyresult.working_period_end), y: this.keyresult.goal*0.85 }
        ];
      },
      renderChart() {
        const ctx = this.$refs.myChart.getContext('2d');
        this.myChart = new Chart(ctx, {
          type: 'line',
          data: {
            datasets: [{
              label: 'Check-in',
              data: this.checkinData,
              fill: false,
              borderColor: 'rgba(0, 175, 240, 1)',
              tension: 0.1,
              // z: 7,
            },
            {
              label: 'Ziel',
              data: this.goalLine,
              fill: false,
              borderColor: 'rgba(0, 0, 0, 1)',
              tension: 0.1,
              pointRadius: 0,
              pointHoverRadius: 0,
              // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',

              // z: -10,
            },
            {
              label: 'Off track',
              data: this.dangerLine,
              fill: true,
              borderColor: 'rgba(0, 0, 0, 0)',
              backgroundColor: 'rgba(255, 55, 155, 1)', // Use backgroundColor for fill color
              tension: 0.1,
              pointRadius: 0,
              pointHoverRadius: 0,
              // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
              // z: 200,
            },
            {
              label: 'Progressing',
              data: this.goalLine,
              fill: true,
              borderColor: 'rgba(0, 0, 0, 0)',
              backgroundColor: 'rgba(255, 235, 0, 1)', // Use backgroundColor for fill color
              tension: 0.1,
              pointRadius: 0,
              pointHoverRadius: 0,
              // pointHoverBackgroundColor: 'rgba(0, 0, 0, 0)',
              // z: -10,
            },
          ]
          },
          options: {
            plugins: {
              title: {
                display: true,
                text: this.keyresult.name
              }
            },
            scales: {
              x: {
                type: 'time',
                time: {
                  unit: 'day'
                },
                grid: {
                  z: 10, // Ensure grid lines are in the foreground
                },
              },
              y: {
                beginAtZero: true,
                grid: {
                  z: 10, // Ensure grid lines are in the foreground
                },
                // max: 100 // Set the maximum value for the y-axis
              }
            }
          }
        });
      }
    
      
    //   updateChartData() {
    //     const labels = this.checkins.map(checkin => new Date(checkin.date_added));
    //     const data = this.checkins.map(checkin => checkin.value);
  
    //     this.chartData.labels = labels;
    //     this.chartData.datasets[0].data = data;
    //   },
    },
  };
  </script>
  
  <style scoped>
  .chart-container {
    position: relative;
    height: 400px;
  }
  </style>