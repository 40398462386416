<template>
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
        Firmen-vision hinzufügen
    </strong>
    <table>
        <tbody>
            <tr>
                <td class="vertical-center">Name:</td>
                <td class="vertical-center">
                    <div>
                        <input type="text" class="input-field-big" placeholder="Neue Vision" v-model="name">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
        <button class="button is-dark" @click="submitForm">Speichern</button>
    </div>
    <div class="notification is-danger mt-4" v-if="errors.length">
        <p v-for="error in errors" v-bind:key="error">{{ error }}</p>
    </div>
    <div class="flex-center">
        <span class="help-icon" @mouseover="showHelpText = true" @mouseleave="showHelpText = false">
            ?
        </span>
        <span v-if="showHelpText" class="help-text">Your help text goes here</span>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'Modal_AddVision',
    emits: ['visionAdded'],
    data() {
        return {
            name: '',
            errors: [],
            showHelpText: false,
        }
    },
    methods: {
        submitForm() {
            this.errors = []
            if (this.name === '') {
                this.errors.push('The name field is missing!')
            }
            if (!this.errors.length) {
                this.addVision();
            }
        },
        async addVision() {
            this.$store.commit('setIsLoading', true)
            // console.log('Adding product:', this.name, this.progress, this.progress_unit, this.status, this.team_users_ids)
            await axios
                .post('/api/v1/visions/', 
                {
                name: this.name,
                },
                )
                .then(response => {
                // console.log('Response:', response.data);
                this.$emit('visionAdded');
                })
                .catch(error => {
                    if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    } else if (error.request) {
                    // The request was made but no response was received
                    console.log(error.request);
                    } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                    }
                    console.log(error.config);
                })
            this.$store.commit('setIsLoading', false)
        }
    }
}
</script>
