<template>
    <div class="flex flex-wrap max-w-3xl">
        <div header class="w-full text-3xl text-center pb-3">Mein Account</div>

        <div class="flex flex-wrap w-full items-center">
            <div class="column">
                <div class="h1">E-Mail:</div>
            </div>
            <div class="column">
                <div class="h1">{{ this.userData.email }}</div>
            </div>
            <div class="column">
                <button @click="isEditEmailModalOpen = true" class="actionButton">Email ändern</button>
                <Teleport to="#modal">
                    <Transition name="modal">
                        <div v-if="isEditEmailModalOpen" class="modal-bg" @click.self="isEditEmailModalOpen = false">
                            <div class="modal">
                                <button @click="isEditEmailModalOpen = false" class="close-btn">x</button>
                                <Modal_MyAccount_ChangeUserEmail
                                v-bind:userData="userData"
                                v-on:emailChanged="handleEmit"
                                />
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>  
        </div>

        <div class="flex flex-wrap w-full items-center">
            <div class="column">
                <div class="h1">Name:</div>
            </div>
            <div class="column">
                <div class="h1">{{ this.userData.first_name }} {{ this.userData.last_name }}</div>
            </div>
            <div class="column">
                <button @click="isEditNameModalOpen = true" class="actionButton">Vor- und Nachnamen ändern</button>
                <Teleport to="#modal">
                    <Transition name="modal">
                        <div v-if="isEditNameModalOpen" class="modal-bg" @click.self="isEditNameModalOpen = false">
                            <div class="modal">
                                <button @click="isEditNameModalOpen = false" class="close-btn">x</button>
                                <Modal_MyAccount_ChangeUserFullName
                                v-bind:userData="userData"
                                v-on:FullNameChanged="handleEmit"
                                />
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>  
        </div>

        <div class="flex flex-wrap w-full items-center">
            <div class="column">
                <div class="h1">Passwort:</div>
            </div>
            <div class="column">
                <div class="h1"> ******** </div>
            </div>
            <div class="column">
                <button @click="isEditPasswordModalOpen = true" class="actionButton">Passwort ändern</button>
                <Teleport to="#modal">
                    <Transition name="modal">
                        <div v-if="isEditPasswordModalOpen" class="modal-bg" @click.self="isEditPasswordModalOpen = false">
                            <div class="modal">
                                <button @click="isEditPasswordModalOpen = false" class="close-btn">x</button>
                                <Modal_MyAccount_ChangeUserPassword
                                v-bind:userData="userData"
                                v-on:passwordChanged="handleEmit"
                                />
                            </div>
                        </div>
                    </Transition>
                </Teleport>
            </div>  
        </div>

        <div class="flex flex-wrap w-full items-center">
            <div class="column">
                <div class="h1">Lizenznummer:</div>
            </div>
            <div class="column">
                <div class="h1">{{ this.userData.license }}</div>
            </div>
            <div class="column">
                <div class="h1">&nbsp;</div>
            </div>
        </div>

        <!-- <div v-if="!this.userData.license" class="flex flex-wrap w-full items-center">
            <div class="column control">
                <input type="text" class="input" v-model="licenseNumber">
            </div>
            <div class="column">
                <button @click="activateLicense()" class="button is-ghost">Lizenz aktivieren</button>
            </div>
            <div class="column">
                <div class="h1">&nbsp;</div>
            </div>
        </div> -->
        <div class="flex flex-wrap w-full items-center">
            <div class="column  ">
                    <div class="h1">Lizenztyp:</div>
            </div>
            <div class="column  ">
                <div class="h1">{{ this.Lizenztyp }}</div>
            </div>
            <div class="column">
                <div class="h1">&nbsp;</div>
            </div>
        </div>
        <div class="flex flex-wrap w-full items-center">
            <div class="column  ">
                    <div class="h1">Firma:</div>
            </div>
            <div class="column ">
                <div class="h1">{{ this.userData.workspace }}</div>
            </div>
            <div class="column">
                <div class="h1">&nbsp;</div>
            </div>
        </div>


        <div class="column">
            &nbsp;
        </div>

        <div class="ml-3 flex flex-wrap w-full items-center">
            <button @click="logout()" class="button is-danger">Log out</button>
        </div>
    </div>
</template>

<script>
import Modal_MyAccount_ChangeUserName from '@/components/Modal_MyAccount_ChangeUserName.vue';
import Modal_MyAccount_ChangeUserFullName from '@/components/Modal_MyAccount_ChangeUserFullName.vue';
import Modal_MyAccount_ChangeUserEmail from '@/components/Modal_MyAccount_ChangeUserEmail.vue';
import Modal_MyAccount_ChangeUserPassword from '@/components/Modal_MyAccount_ChangeUserPassword.vue';
import axios from 'axios'
import { toast } from 'bulma-toast'
import Teams from './Teams.vue';

export default {
    name: 'MyAccount',
    components: {
        Modal_MyAccount_ChangeUserName,
        Modal_MyAccount_ChangeUserFullName,
        Modal_MyAccount_ChangeUserEmail,
        Modal_MyAccount_ChangeUserPassword,
    },
    data() {
        return {
            licenseNumber: '',
            userData: '',
            isEditUsernameModalOpen: false,
            isEditNameModalOpen: false,
            isEditEmailModalOpen: false,
            isEditPasswordModalOpen: false,
            // Administrator: '',
            // Teamlead: '',

        }
    },
    mounted() {
        this.fetchUserData();
        // this.setGerman();
    },
    computed: {
        Lizenztyp() {
            if (this.userData.is_company_admin) {
                return 'Administrator';
            } else if (this.userData.is_company_teamlead) {
                return 'Teamlead';
            } else {
                return 'Standard';
            }
        },
    },
    methods: {
        logout() {
            axios.defaults.headers.common["Authorization"] = ""

            localStorage.removeItem("token")
            // localStorage.removeItem("username")
            localStorage.removeItem("userid")

            this.$store.commit('removeToken')

            this.$router.push('/log-in-to-okr')
        },
        async fetchUserData() {
            try {
                const response = await axios.get('/api/v1/me/'); 
                // const response = await axios.get('/api/v1/users/me', ); // TODO would be good to use the backend from djoser
                this.userData = response.data;
                console.log('userData:', this.userData);
                // console.log('workspace:', this.userData.workspace);
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        },


        async activateLicense() {
            console.log(this.licenseNumber)
            try {
                const response= await axios.put('/api/v1/licenses/', {
                    license_number: this.licenseNumber
                    // license_number: '12345'
                })
                console.log(response.data.message)
                
                toast({
                        message: response.data.message,
                        // message: 'License activated, your account is ready to use!',
                        type: 'is-success',
                        dismissible: true,
                        pauseOnHover: true,
                        duration: 2000,
                        position: 'bottom-right',
                    })
                window.location.reload()
            } catch (error) {
                let errorMessage = 'An error occurred while activating the license.';

                if (error.response) {
                    if (error.response.data && error.response.data.message) {
                        errorMessage = error.response.data.message;
                    } else if (error.response.status === 404) {
                        errorMessage = 'License not found.';
                    }
                }
                toast({
                    message: errorMessage,
                    type: 'is-danger',
                    dismissible: true,
                    pauseOnHover: true,
                    duration: 2000,
                    position: 'bottom-right',
                })

                if (error.request) {
                    console.log('Error request:', error.request);
                } else {
                    console.log('Error activating license:', error);
                }
                // console.error('Error activating license:', error);
            }
        },
        handleEmit() {
            this.isEditNameModalOpen = false;
            this.isEditEmailModalOpen = false;
            this.isEditPasswordModalOpen = false;
            this.isEditUsernameModalOpen = false;
            this.fetchUserData()       
        },
    }
}
</script>