<template>
  <div>
    <!-- Your template code here -->
    <strong class="is-flex is-justify-content-center is-align-items-center p-3">
      Passwort ändern
    </strong>
    <table>
      <tbody>
        <tr>
          <td class="vertical-center">Altes Passwort:</td>
          <td class="vertical-center">
            <div>
              <input type="password" class="input-field-big" v-model="current_password" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="vertical-center">Neues Passwort:</td>
          <td class="vertical-center">
            <div>
              <input type="password" class="input-field-big" v-model="new_password" />
            </div>
          </td>
        </tr>
        <tr>
          <td class="vertical-center">Neues Passwort wiederholen:</td>
          <td class="vertical-center">
            <div>
              <input type="password" class="input-field-big" v-model="re_new_password" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="is-flex is-justify-content-center is-align-items-center p-3">
      <button type="submit" class="button is-dark" @click="changePassword">Speichern</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Modal_MyAccount_ChangeUserPassword',
  emits: ['passwordChanged'],
  data() {
    return {
      current_password: '',
      new_password: '',
      re_new_password: '',
    };
  },
  methods: {
    async changePassword() {
      try {
        const response = await axios.post('/api/v1/users/set_password/', {
          current_password: this.current_password,
          new_password: this.new_password,
          re_new_password: this.re_new_password,
        });
        // alert('Password changed successfully.');
        this.$emit('passwordChanged');
      } catch (error) {
        if (error.response) {
          console.error('Error changing password:', error.response.data);
          // alert(`Error changing username: ${error.response.data.detail}`);
        } else {
          console.error('Error changing password:', error.message);
          // alert('An error occurred while changing the username.');
        }
      }
    },
  },
};
</script>