<template>
  <div v-if="!isLoading" class="flex flex-wrap">
    <!-- <div class="column is-12"> -->
      <!-- <h2 class="is-size-2 has-text-centered pb-6">Sustevia</h2> -->
    <!-- </div> -->
    <div header class="column is-12">
      <h2 class="is-size-3 has-text-centered pb-3">OKR Archiv</h2>
    </div>

    <table class="table is-fullwidth no-header-border table-fixed bg-transparent">
      <thead>
        <Component_OKR_Header/>
      </thead>
      <tbody>
        <Component_OKR_Objective
          v-for="objective in objectives"
          v-bind:key="objective.id"
          v-bind:objective="objective"
          v-bind:keyresults="keyresults"
          v-bind:permissions="permissions"
          v-bind:isArchive=true
          v-bind:isPlanningActive="isPlanningActive"
          v-bind:activeWorkingPeriods="activeWorkingPeriods"
          v-on:productAdded="handleObjectiveUpdated"
          >
        </Component_OKR_Objective>
        <!-- v-bind:planning="planning" -->


        <!-- {if perms.product.add_ojective %} -->
        <!--  -->

      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios'
import draggable from 'vuedraggable'

// import Component_OKR_Archive_Objective from '@/components/Component_OKR_Archive_Objective'
import Modal_AddObjective from '@/components/Modal_AddObjective'
import Component_OKR_Objective from '@/components/OKR/Component_OKR_Objective.vue'
import Component_OKR_Header from '@/components/Component_OKR_Header.vue'


import { onClickOutside } from '@vueuse/core'
// import {ref} from 'vue'


export default {
  name: 'HomeView',
  components: {
    draggable,
    // Component_OKR_Archive_Objective,
    Component_OKR_Objective,
    Component_OKR_Header,
    Modal_AddObjective
  },
  data() {
    return {
      objectives: [],
      keyresults: [],
      activeWorkingPeriods: [],
      planning: '',
      permissions: [],
      isLoading: true,
    }
  },
  mounted() {   
    document.title = 'Home | Objectives',
    this.loadData();
  },
  methods: {
    async loadData() {
      await Promise.all([
        this.getObjectives(),
        this.getKeyresults(),
        this.getPermissions(),
        this.getPlanning(),
        this.getActiveWorkingPeriods(),
      ]);
      this.isLoading = false;
    },
    // checkMove(event) {
    //   return event.related.className.includes('drag-handle');
    // },
    async getObjectives() {
      // console.log('fetching archived objectives');
      this.$store.commit('setIsLoading', true);
      try {
        const response = await axios.get('/api/v1/objectives-archived/');
        this.objectives = response.data;
      } catch (error) {
        console.error('Error fetching products:', error);
      }
      this.$store.commit('setIsLoading', false);
      // console.log('objectives:', this.objectives);

    },
    async getKeyresults() {
      this.$store.commit('setIsLoading', true);
      // console.log('fetching archived keyresults');
      try {
        const response = await axios.get('/api/v1/keyresults-archived/');
        this.keyresults = response.data;
        // console.log('keyresults:', this.keyresults);
      } catch (error) {
        console.error('Error fetching keyresults:', error);
      }
      this.$store.commit('setIsLoading', false);
    },
    async getPlanning() {
      this.$store.commit('setIsLoading', true);
      try {
        const response = await axios.get('/api/v1/plannings/');
        this.planning = response.data;
        if (this.planning.is_active === true) {
          this.isPlanningActive = true;
        }
        else {
          this.isPlanningActive = false;
        }
      } catch (error) {
        console.error('Error fetching plannings:', error);
      }
      this.$store.commit('setIsLoading', false);
    },
    async getPermissions() {
      try {
        const response = await axios.get('/api/v1/users/me/permissions');
        this.permissions = response.data.permissions;
        // console.log('permissions OKRs:', this.permissions);
      } catch (error) {
        console.error('Error fetchings permissions:', error);
      }
    },
    async getActiveWorkingPeriods() {
      try {
        const response = await axios.get('api/v1/working-periods');
        this.activeWorkingPeriods = response.data
        // console.log('active working periods:', this.activeWorkingPeriods)
      }
      catch (error) {
        console.error('Error fetchings active working periods:', error);
      }
    },
    async handleObjectiveDeleted(productId) {
      // Fetch products again after a product is deleted
      await this.loadData();
    },
    async handleObjectiveUpdated(productId) {
      // Fetch products again after a product is deleted
      // await this.getObjectives();
      await this.loadData();

    },
    async handleObjectiveAdded() {
      // Fetch products again after a product is deleted
      await this.loadData();
      await this.loadData();
      await this.loadData();
    }
  }
}
</script>

<style>
.no-header-border thead th {
  border-bottom: none;
}
</style>

