<template>
    <div v-if="!isLoading" class="flex flex-wrap">
        <div header class="w-full">
            <h2 class="is-size-3 has-text-centered pb-3 ">Lizenzverwaltung</h2>
            <h2>&nbsp;</h2>
        </div>

        <table class="table w-full max-w-3xl mx-auto no-header-border table-fixed bg-transparent">
            <thead>
                <tr>
                    <td class="font-bold w-[200%]"><span>Lizenztyp</span></td>              
                    <td class="font-bold w-[100%] text-center"><span>Gesamt</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Frei</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Vergeben</span></td>
                    <td class="font-bold w-[100%] text-center"><span>Aktiviert</span></td>
                </tr>
            </thead>
            
            <tbody>
                <!-- TODO make this dependent on permissions or user status -->
                <tr class="tableRow" v-if="this.licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div>Administrator:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.admin.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow" v-if="licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div>Teamlead:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.teamlead.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow">
                    <td class="verticalCenterTD text-left">
                        <div>Standard:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard.activated.length }}</div>
                    </td>
                </tr>
                <tr class="tableRow" v-if="this.licenses.admin.all.length">
                    <td class="verticalCenterTD text-left">
                        <div class="ml-6">Davon an Teamleads übertragen:</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.all.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.free.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.used.length }}</div>
                    </td>
                    <td class="verticalCenterTD text-center">
                        <div>{{ this.licenses.standard_redistributed.activated.length }}</div>
                    </td>
                </tr>
                <tr>
                    <div class="flex flex-wrap w-full items-center">
                        <button @click="isAssignLicensesModalOpen = true" class="actionButton">Lizenzen vergeben</button>
                        <Teleport to="#modal">
                            <Transition name="modal">
                                <div v-if="isAssignLicensesModalOpen" class="modal-bg" @click.self="isAssignLicensesModalOpen = false">
                                    <div class="modal">
                                        <button @click="isAssignLicensesModalOpen = false" class="close-btn">x</button>
                                        <Modal_Company_AssignLicenses
                                        v-bind:licenses="licenses"
                                        v-on:licenseAssigned="handleEmit"
                                        />
                                    </div>
                                </div>
                            </Transition>
                        </Teleport>
                    </div>  
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import Modal_Company_AssignLicenses from '@/components/Modal_Company_AssignLicenses.vue';
import axios from 'axios';

export default {
    name: 'MyAccount',
    components: {
        Modal_Company_AssignLicenses,
        // Modal_Company_LicenseDetails,
    },
    props: {
        me: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            licenses: {
                admin: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                teamlead: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                standard: {
                all: [],
                used: [],
                free: [],
                activated: []
                },
                standard_redistributed: {
                all: [],
                used: [],
                free: [],
                activated: []
                }
            },
            isAssignLicensesModalOpen: false,
        }
    },
    mounted() {
        this.getLicenses();
    },
    methods: {
        async getLicenses() {
            // console.log('getLicenses');
            try {
                const response = await axios.get('/api/v1/company-licenses/');
                // console.log(response.data);

                Object.keys(this.licenses).forEach(category => {
                    this.licenses[category].all = [];
                    this.licenses[category].used = [];
                    this.licenses[category].free = [];
                    this.licenses[category].activated = [];
                });

                // TODO can be made much more simple
                // Categorize licenses in a single loop)
                response.data.forEach(license => {
                    console.log('licenses:', license)
                    console.log('redistributor:', license.redistributor)
                    console.log('me:', this.me)

                    if (license.is_company_admin) {
                        this.licenses.admin.all.push(license);
                        if (license.user) {
                            this.licenses.admin.used.push(license);
                            if (license.is_activated) {
                                this.licenses.admin.activated.push(license);
                            }
                        } else {
                            this.licenses.admin.free.push(license);
                        }
                    } else if (license.is_company_teamlead) {
                        this.licenses.teamlead.all.push(license);
                        if (license.user) {
                            this.licenses.teamlead.used.push(license);
                            if (license.is_activated) {
                                this.licenses.teamlead.activated.push(license);
                            }
                        } else {
                            this.licenses.teamlead.free.push(license);
                        }
                    } else {
                        this.licenses.standard.all.push(license);
                        if (license.user) {
                            this.licenses.standard.used.push(license);
                            if (license.is_activated) {
                                this.licenses.standard.activated.push(license);
                            }
                            // TODO Luka here me.id is not the correct ID. THis whole thing should be overworked anyways
                        } else if (license.redistributor && license.redistributor != this.me.id) {
                            this.licenses.standard_redistributed.all.push(license);
                            if (license.user) {
                                this.licenses.standard_redistributed.used.push(license);
                                if (license.is_activated) {
                                    this.licenses.standard_redistributed.activated.push(license);
                                }
                            } else {
                            this.licenses.standard_redistributed.free.push(license);
                            }
                        } else {
                            this.licenses.standard.free.push(license);
                        }
                    }
                });
                console.log('Licenses:', this.licenses);
            } catch(error) {
                console.log(error);
            };
        },
        handleEmit() {
            console.log("handleEmit Licenses")
            this.getLicenses();
            this.isAssignLicensesModalOpen = false;
        }
    }
}


</script>