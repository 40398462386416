<template>
    <tr>
        <td class="font-bold w-[100%]"><span>Nutzer</span></td>    
        <td class="font-bold w-[150%] "><span>Objektname</span></td>
        <td class="font-bold w-[40%] text-center"><span>Aktion</span></td>
        <td class="font-bold w-[40%] text-center"><span>Objekttyp</span></td>
        <td class="font-bold w-[80%] text-center"><span>Zeit</span></td>
    </tr>
</template>

<script>

export default {
    name: 'Component_Changelog_Header',
}
</script>
